import { Navigate } from 'react-router-dom'
// import underConstruction from '../assets/images/under-construction.png' 

const UnderConstruction = () => {
return (
    <Navigate to='/home' />
    // <div className='h-screen bg-orange-300 flex items-center justify-center flex-col'>
    //     <img className='h-64' src={underConstruction}/>
    //     <h1 className='text-5xl'>Under Construction</h1>
    // </div>
)}

export default UnderConstruction